import store from '../store'
let imageCache = {}

const fetchImage = (src, cb, errorCb) => {
  let img = new Image()
  img.crossOrigin = 'Anonymous'
  img.onload = () => {
    let canvas = document.createElement('CANVAS')
    let context = canvas.getContext('2d')
    canvas.height = img.naturalHeight
    canvas.width = img.naturalWidth
    context.drawImage(img, 0, 0)
    cb(canvas.toDataURL('image/png'))
  }
  img.onerror = errorCb
  img.src = src
  if (img.complete || img.complete === undefined) {
    img.src = 'data:image/gifbase64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='
    img.src = src
  }
}

const tokenizeUrl = url => {
  if (url.indexOf('_token') > -1 || url.indexOf('data:') > -1 || !store.getters.jwtToken) {
    return url
  }
  return `${url}${url.indexOf('?') > -1 ? '&' : '?'}_token=${store.getters.jwtToken}`
}
export const toDataURL = (imageUrl) => {
  return new Promise((resolve, reject) => {
    if (imageCache[imageUrl]) {
      resolve(imageCache[imageUrl])
    } else {
      fetchImage(tokenizeUrl(imageUrl), dataUrl => {
        imageCache[imageUrl] = dataUrl
        resolve(dataUrl)
      }, reject)
    }
  })
}

const trim = canvas => {
  let ramp = 50
  let context = canvas.getContext('2d')
  let copy = document.createElement('canvas')
    .getContext('2d')
  let pixels = context.getImageData(0, 0, canvas.width, canvas.height)
  let index
  let x
  let y
  let w = canvas.width
  let h = canvas.height
  let pix = { x: [], y: [] }

  for (y = 0; y < h; y++) {
    for (x = 0; x < w; x++) {
      index = (y * w + x) * 4
      if (pixels.data[index + 3] > 0) {
        pix.x.push(x)
        pix.y.push(y)
      }
    }
  }
  pix.x.sort((a, b) => a - b)
  pix.y.sort((a, b) => a - b)
  const n = pix.x.length - 1

  let trimHeight = pix.y[n] - pix.y[0]
  let trimWidth = pix.x[n] - pix.x[0]
  let trimmed = context.getImageData(pix.x[0], pix.y[0], trimWidth, trimHeight)

  copy.canvas.width = trimWidth + ramp
  copy.canvas.height = trimHeight + ramp
  copy.putImageData(trimmed, ramp / 2, ramp / 2)

  // open new window with trimmed image:
  return copy.canvas
}

export const trimSvgImage = (dataURL) => {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement('CANVAS')
    let context = canvas.getContext('2d')
    let img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = () => {
      canvas.width = img.width
      canvas.height = img.height
      context.drawImage(img, 0, 0)
      resolve(trim(canvas))
    }
    img.onerror = reject
    img.src = dataURL
  })
}

export const loadCORSImage = src => {
  return new Promise((resolve, reject) => {
    let image = new Image()
    image.crossOrigin = 'Anonymous'
    image.src = src

    image.onload = function () {
      resolve(image)
    }

    image.onerror = function (e) {
      reject(e)
    }
  })
}

const ENC = {
  '+': '-',
  '/': '_',
  '=': '.'
}
const DEC = {
  '-': '+',
  '_': '/',
  '.': '='
}

/**
 * encode base64 string url safe
 * @param {String} string
 * @return {String} url-safe-base64 encoded
 */
export const encodeSKU = (string) => {
  const base64 = btoa(string)
  return base64.replace(/[+/=]/g, (m) => ENC[m])
}

/**
 * decode url-safe-base64 string to base64
 * @param {String} safe - url-safe-base64 string
 * @return {String} base64 encoded
 */
export const decodeSKU = (safe) => {
  return atob(safe.replace(/[-_.]/g, (m) => DEC[m]))
}
