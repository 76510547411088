import { loadCORSImage } from './toDataURL'
import colorNamer from 'color-namer'
import { capitalizeWords } from '../utils'

const isBlack = (r, g, b, threshold = 20) => {
  return r < threshold && g < threshold && b < threshold
}

export const invertImageData = (imageData) => {
  for (let i = 0; i <= imageData.length; i += 4) {
    // alpha not null
    if (imageData[i + 3]) {
      let red = imageData[i]
      let green = imageData[i + 1]
      let blue = imageData[i + 2]
      imageData[i] = 255 - red
      imageData[i + 1] = 255 - green
      imageData[i + 2] = 255 - blue
    }
  }
  return imageData
}
/**
 * Converts color components to hsl
 * @param r
 * @param g
 * @param b
 * @returns {number[]}
 */
export const RGBtoHSL = function (r, g, b) {
  r /= 255
  g /= 255
  b /= 255

  let max = Math.max(r, g, b)
  let min = Math.min(r, g, b)
  let h; let s; let l = (max + min) / 2

  if (max === min) {
    h = s = 0 // achromatic
  } else {
    let d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break
      case g: h = (b - r) / d + 2; break
      case b: h = (r - g) / d + 4; break
    }
    h /= 6
  }

  return [h, s, l]
}

/**
 * Convert hsl to rgb
 * @param hsl
 * @returns {number[]}
 * @constructor
 */
export const HSLtoRGB = function (hsl) {
  // in JS 1.7 use: let [h, s, l] = hsl;
  let h = hsl[0]
  let s = hsl[1]
  let l = hsl[2]

  let r; let g; let b

  let hue2rgb = function (p, q, t) {
    if (t < 0) t += 1
    if (t > 1) t -= 1
    if (t < 1 / 6) return p + (q - p) * 6 * t
    if (t < 1 / 2) return q
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
    return p
  }

  if (s === 0) {
    r = g = b = l // achromatic
  } else {
    let
      q = l < 0.5 ? l * (1 + s) : l + s - l * s
    let p = 2 * l - q
    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return [r * 0xFF, g * 0xFF, b * 0xFF]
}
export const colorizeImageData = (imageData, inputColor) => {
  const colorIsBlack = isBlack(inputColor.r, inputColor.g, inputColor.b)
  let color = lightenColor(inputColor, 0.8)
  if (colorIsBlack) {
    color = lightenColor(inputColor, 0.9)
    // return invertImageData(imageData)
  }
  for (let i = 0; i <= imageData.length; i += 4) {
    // alpha not null
    if (imageData[i + 3]) {
      let red = imageData[i]
      let green = imageData[i + 1]
      let blue = imageData[i + 2]
      imageData[i] = Math.floor(red * color.r / 255) // red
      imageData[i + 1] = Math.floor(green * color.g / 255) // green
      imageData[i + 2] = Math.floor(blue * color.b / 255) // blue
    }
  }
  return imageData
}

export const componentToHex = c => {
  let hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export const rgbToHex = (r, g, b) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? {
    r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16)
  } : null
}

const applyBounds = (value, leftBound, rightBound) => {
  if (value < leftBound) return leftBound
  if (value > rightBound) return rightBound
  return value
}
/**
 * Lighten color
 * @param {{r: Number, g: Number, b:Number}} color
 * @param {number} percent (0-1)
 * @returns {{r: Number, g: Number, b:Number}}
 */
function lightenColor (color, percent) {
  let R = color.r || 20
  let G = color.g || 20
  let B = color.b || 20
  const [H, S, L] = RGBtoHSL(R, G, B)
  const fixedColor = [
    applyBounds(H * (1 - percent / 10), 0, 1),
    applyBounds(S * percent, 0, 1),
    applyBounds(L / percent, 0, 1)
  ]
  const SC = HSLtoRGB(fixedColor)
  R = applyBounds(SC[0], 0, 255)
  G = applyBounds(SC[1], 0, 255)
  B = applyBounds(SC[2], 0, 255)
  const newComponents = { r: R, g: G, b: B }
  return {...color, ...(R === 255 && G === 255 && B === 255 ? {} : newComponents)}
}

/**
 *
 * @param {{url: String, colorable: Boolean}} image
 * @param width
 * @param height
 * @param {{r: Number, g: Number, b: Number}|null} color
 */
export const loadColoredImage = (image, width, height, color = null) => {
  if (!color || !image.colorable) {
    return loadCORSImage(image.url)
  } else {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.imageSmoothingEnabled = true
    return loadCORSImage(image.url).then(imageObject => {
      canvas.width = imageObject.width
      canvas.height = imageObject.height
      ctx.drawImage(imageObject, 0, 0, imageObject.width, imageObject.height)
      const imageData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height)
      if (color && color.r >= 0) {
        colorizeImageData(imageData.data, color)
        canvas.getContext('2d').putImageData(imageData, 0, 0)
      }
      return canvas
    })
  }
}
/**
 *
 * @param {Array<{url: String, colorable: Boolean}>} imagesToDownload
 * @param {Number} width
 * @param {Number} height
 * @param {{r: Number, g: Number, b: Number}|null} color
 * @returns {*}
 */
export const combineCloth = (imagesToDownload, width, height, color = null) => {
  const c = document.createElement('canvas')
  c.width = width
  c.height = height
  const ctx = c.getContext('2d')
  ctx.imageSmoothingEnabled = true
  return Promise.all(imagesToDownload.map(i => loadColoredImage(i, width, height, color))).then(images => {
    images.forEach(image => {
      console.log(image)
      ctx.drawImage(image, 0, 0, width, height)
    })
    return c
  })
}
/**
 * Name a color if no name given
 * @param {{color:string, name:string}|string} color
 * @returns {string}
 */
export const nameHexColor = (color) => {
  const hex = ('' + (color.color || color)).trim().replace(/.*(#[\d\w]{3,6,8}).*/, '$1')
  if (color.name) {
    return capitalizeWords(color.name)
  }
  if (hex !== '#ffffff') {
    const names = colorNamer(hex)
    if (names.ntc && names.ntc.length) {
      return capitalizeWords(names.ntc[0].name)
    }
  }
  return ''
}

/**
 * Name a color if no name given
 * @param {{color:string, name:string}|string} color
 * @returns {Number}
 */
export const colorDistanceFromWhite = (color) => {
  const hex = ('' + (color.color || color)).trim().replace(/.*(#[\d\w]{3,6,8}).*/, '$1')
  const rgb = hexToRgb(hex)
  if (rgb) {
    return ((rgb.r * 299) + (rgb.g * 587) + (rgb.b * 114)) / 1000 // rgb.r + rgb.g + rgb.b / 2
  } else {
    const names = colorNamer(hex, { pick: ['basic'] })
    if (names.basic && names.basic.length) {
      const white = names.basic.find(color => color.name === 'white')
      return parseFloat('' + white.distance)
    }
  }
  return 0
}
